<template>
  <UiPage
    :title="$t('app.page.purchases')"
    :titleTemplate="$t('app.page.purchases')"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <Container
        class="content"
        :preloader="preloader"
      >
        <template #content>
          <div class="content__top">
            <div class="content__top__tabs">
              <TabButton
                :text="$t('views.default.transaction.purchases.purchases')"
                :value="'TransactionsPurchase'"
                :active="$route.name"
                @click="$router.push({ name: 'TransactionsPurchase' })"
              />

              <TabButton
                :text="$t('views.default.transaction.orders.orders')"
                :value="'TransactionsOrder'"
                :active="$route.name"
                @click="$router.push({ name: 'TransactionsOrder' })"
              />
            </div>

            <FilterRangeDate
              :value="date"
              @updateDate="date = $event"
            />
          </div>

          <RouterView v-slot="{ Component }">
            <KeepAlive>
              <Component
                :is="Component"
                :key="$route.fullPath"
                :date="date"
                @preloader="preloader = $event"
              />
            </KeepAlive>
          </RouterView>
        </template>
      </Container>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import TabButton from '@/ui/buttons/TabButton.vue'
import FilterRangeDate from '@/ui/FilterRangeDate.vue'

export default {
  components: {
    UiPage,
    Container,
    BackButton,
    TabButton,
    FilterRangeDate
  },
  data () {
    return {
      preloader: true,
      date: null
    }
  },
  deactivated () {
    if (this.date !== null) {
      this.date = null
    }
  },
  watch: {
    '$route' () {
      this.date = null
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  padding: 20px;
  border-radius: 15px;

  .content__top {
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #9CA3B8;

    .content__top__tabs {
      display: flex;
      align-items: center;
    }

    .content__top__settings {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(var(--primary-color));
      border-radius: 50%;
      transition: .2s;

      &:hover {
        background: #577FFF;
      }

      &:active {
        background: rgb(var(--color-dark));
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding: 15px 15px 20px;
  }
}
</style>
